<template>
  <div>

    <input hidden type="file" id="file" ref="file" @change="handle_file_upload" />
    
    <div class="">
      <b-row>
        <b-col>
          
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm"
            style="float: right;"
            @click="upload_sales_clicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SALES.UPLOAD_SALES') }}</a
          >

          <memlist-select-input2
            style="float: right; margin-top: 0px; margin-left: 4px; margin-right: 4px;"
            id="type"
            name="type"
            layout="vertical"
            v-model="company_type_option"
            :items="company_type_options"
          />

        </b-col>
      </b-row>

    </div>
  

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { upload_file } from '@/core/services/fileUpload';
import { mapGetters } from 'vuex';

import axios from 'axios';


export default {
  name: 'SalesFileUpload',
  components: {
    
  },
  mixins: [ toasts ],
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    
    company_type_options() {
      return [
        { text: this.$t('SALES.SALES_COMPANY_TYPES.IDEELL'), value: 'IDEELL' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.TROSSAMFUND'), value: 'TROSSAMFUND' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.AKASSA'), value: 'AKASSA' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.EKONOMISK_FORENING'), value: 'EKONOMISK_FORENING' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.OTHER_STIFTELSER'), value: 'OTHER_STIFTELSER' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.SAMFALLIGHET'), value: 'SAMFALLIGHET' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.UNKNOWN'), value: 'UNKNOWN' },
      ]
    },
    
  },
  props: [],
  mounted() {
  
  },
  data() {
    return {
      company_type_option: null,
    };
  },
  methods: {
    upload_sales_clicked() {
      if (!this.company_type_option) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.ERROR_SELECT_TYPE'));
        return;
      }
      
      this.$refs.file.value = null;
      this.select_file();
    },

    async handle_file_upload() {
      const file = this.$refs.file.files[0];

      if (file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      try {
        const newfile = await upload_file(file, { file_type: 'FILE_CSV', is_public: false });

        await axios.post(`/sales/import`, { type: this.company_type_option, file_id: newfile.file_id });

        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));
      }
      catch (err) {
        console.error('handle_file_upload', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
      }
      
    },

    select_file() {
      this.$refs.file.click();
    },
    
  }
};
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>

